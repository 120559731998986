import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Nav, Tab } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

// const options = [
//   { value: "RECEIVED", label: "RECEIVED" },
//   { value: "ACCEPTED", label: "ACCEPTED" },
//   { value: "PACKED", label: "PACKED" },
//   { value: "SHIPPED", label: "SHIPPED" },
//   { value: "CANCELLED", label: "CANCELLED" },
// ];

export default function ShippedModal({
  showModal,
  hideModal,
  onSubmit,
  loading,
  Fields = "Status",
  uniqueStatus,
}) {
  //   const { t } = useTranslation(["common", "ordermanagement"]);
  const [status, setstatus] = useState(null);

  const [TruckingNumber, setTruckingNumber] = useState(null);
  const [LogisticsCompanyName, setLogisticsCompanyName] = useState(null);
  const classes = useStyles();

  const TextChange = (e) => {
    setTruckingNumber(e.target.value);
  };

  const TextChange1 = (e) => {
    setLogisticsCompanyName(e.target.value);
  };
  console.log(TruckingNumber);

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>発送を完了する</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Body */}
          <div>
            {/*{uniqueStatus && (
              <Select
                options={options}
                onChange={handleChange}
                placeholder={t("ordermanagement:modals.select")}
              />
            )}*/}

            <CardHeaderToolbar>
              <div>
                <Tab.Container defaultActiveKey={"SHIPPED"}>
                  <TextField
                    id="standard-basic"
                    label="追跡番号"
                    fullWidth
                    onChange={TextChange}
                    variant="outlined"
                    // style={{ marginTop: "30px", height: 44 }}
                    // multiline
                    // rows={2}
                  />

                  <TextField
                    id="standard-basic"
                    label="運送会社名"
                    fullWidth
                    onChange={TextChange1}
                    variant="outlined"
                    // style={{ marginTop: "30px", height: 44 }}
                    style={{ marginTop: "30px" }}
                    // multiline
                    // rows={2}
                  />
                </Tab.Container>
              </div>
            </CardHeaderToolbar>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ marginRight: "1rem" }}>
              <Button variant="primary  btn-block" onClick={() => hideModal()}>
                キャンセル
              </Button>
            </div>
            <div>
              <Button
                variant="primary  btn-block"
                disabled={!TruckingNumber || !LogisticsCompanyName}
                onClick={() =>
                  onSubmit({
                    key: "SHIPPED",
                    TruckingNumber,
                    LogisticsCompanyName,
                  })
                }
              >
                {loading && (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                送信 <SaveIcon fontSize={"small"} />
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import SVG from "react-inlinesvg";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { DataTable } from "../../components/DataTable";
import { sortCaret, toAbsoluteUrl } from "../../../_metronic/_helpers"
import { query } from "../../network";
import { DeleteSellerDialog } from "../../deleteseller-dialog/DeleteSellerDialog";
import { Filter } from "../../components/Filter"
import { ButtonGroup, Button } from "react-bootstrap";
import { request, getrequest } from "../../network/api";
import Table from '../../components/filtertable';
import SellersTable from '../../components/SellersTable';
import Search from "../Search"
import { BrowserRouter, Route, Link } from "react-router-dom";
export default function SellerCard() {
  const intl = useIntl();
  // const { data, isLoading, isError } = useQuery(
  //   "sellers",
  //   query.getSellers
  // );
  // useEffect(() => {
  //   console.log(data?.data);
  // }, [data])
  const [dialog, setDialog] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  const [dialog1, setDialog1] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });


  var mydata =
    [
      {

        "SellerID": "ORD1",
        "SellerName": "Anjali",
        "Created At": "2021/06/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "PRD1",
        "SellerName": "Anjali",
        "Created At": "2021/04/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "QRD1",
        "SellerName": "Anjali",
        "Created At": "2021/06/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "WRD1",
        "SellerName": "Ginji",
        "Created At": "2021/07/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "DRD1",
        "SellerName": "Varun",
        "Created At": "2021/06/06",
        "Action": "",
        "SellerStatus": "New",
      },
      {

        "SellerID": "ORD1",
        "SellerName": "Biren",
        "Created At": "2021/08/06",
        "Action": "",
        "SellerStatus": "New",
      },


    ];

  const columns = React.useMemo(
    () => [

      {
        Header: '  ',
        columns: [

          {
            Header: 'SellerID',
            accessor: 'SellerID'
          },
          {
            Header: 'SellerName',
            accessor: 'SellerName',
          },

          //   {
          //     Header: 'Created At',
          //     accessor: 'Created At'
          // },

          //   {
          //     Header: 'Status',
          //     accessor: 'status',
          //     Cell: () => {
          //       return (
          //         <span
          //           // className={`label label-lg label-inline label-light-${
          //           //   data === true ? "success" : "danger"
          //           // }`}
          //           className={`label label-lg label-inline label-light-success`}
          //         >
          //           {/* {intl.formatMessage({id:data})} */}
          //          New
          //         </span>
          //       );
          //     },

          // },
          {
            Header: 'Action',
            accessor: 'Action',
            Cell: () => (
              <>
                <ButtonGroup>
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {

                      setDialog({
                        isOpen: true,
                        // ticketId: row.id,
                        // user_id: row.advertiser_id,
                      });
                    }}
                  >
                    {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                    <span className="symbol-label">
                      <SVG
                        className="svg-icon menu-icon"
                        style={{ fill: "blue", width: "17px" }}
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/Edit.svg"
                        )}
                      />
                    </span>
                    {/* </a> */}

                    {/* <span>{data}</span> */}

                  </button>
                  <button
                    className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                    onClick={(e) => {

                      setDialog1({
                        isOpen: true,
                        // ticketId: row.id,
                        // user_id: row.advertiser_id,
                      });
                    }}
                  >
                    {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                    <span className="symbol-label">
                      <SVG
                        className="svg-icon menu-icon"
                        style={{ height: "18px" }}
                        src={toAbsoluteUrl(
                          "/media/svg/icons/Design/delete.svg"
                        )}
                      />
                    </span>
                    {/* </a> */}

                    {/* <span>{data}</span> */}

                  </button>

                </ButtonGroup>
              </>
            ),
          },

        ],
      },
    ],
    []
  )

  const column = [
    {
      dataField: "SellerID",
      classes: "text-center",
      headerClasses: "text-center",
      text: intl.formatMessage({ id: "SELLERID" }),
      // formatter: (prop, row) => `${row.first_name} ${row.last_name}`,
    },
    {
      dataField: "name", classes: "text-center",
      headerClasses: "text-center", text: intl.formatMessage({ id: "SELLERNAME" })
    },
    {
      dataField: "Created At", classes: "text-center",
      headerClasses: "text-center", text: intl.formatMessage({ id: "CREATEDAT" })
    },
    {
      dataField: "SellerStatus",
      classes: "text-center",
      headerClasses: "text-center",
      text: intl.formatMessage({ id: "SELLERSTATUS" }),
      formatter: () => {
        return (
          <span
            // className={`label label-lg label-inline label-light-${
            //   data === true ? "success" : "danger"
            // }`}
            className={`label label-lg label-inline label-light-success`}
          >
            {/* {intl.formatMessage({id:data})} */}
            New
          </span>
        );
      },

    },
    {
      dataField: "Action",
      text: intl.formatMessage({ id: "Action" }),
      classes: "text-center",
      headerClasses: "text-center",
      formatter: () => (
        <>
          <ButtonGroup>
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {

                setDialog({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >
              {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
              <span className="symbol-label">
                <SVG
                  className="svg-icon menu-icon"
                  style={{ fill: "blue", width: "17px" }}
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Design/Edit.svg"
                  )}
                />
              </span>
              {/* </a> */}

              {/* <span>{data}</span> */}

            </button>
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {

                setDialog1({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >
              {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
              <span className="symbol-label">
                <SVG
                  className="svg-icon menu-icon"
                  style={{ height: "18px" }}
                  src={toAbsoluteUrl(
                    "/media/svg/icons/Design/delete.svg"
                  )}
                />
              </span>
              {/* </a> */}

              {/* <span>{data}</span> */}

            </button>

          </ButtonGroup>
        </>
      ),
    },

    // { dataField: "area", text: intl.formatMessage({ id: "area" }) },
  ];

  // useEffect(() => {
  //   console.log(data);
  // }, [data]);
  return (
    <Card>
      <CardHeader title={intl.formatMessage({ id: "SELLERSLIST" })}>
        <CardHeaderToolbar>
          {/* <button type="button" className="btn btn-primary">
            {intl.formatMessage({ id: "Add new Seller" })}
            <Link to="/create" />
          </button> */}
          <Link to="/create" className="btn btn-primary">{intl.formatMessage({ id: "ADDNEWSELLER" })}</Link>
        </CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        {/* <Filter/> */}


        <DeleteSellerDialog
          show={dialog1.isOpen}
          onHide={() => setDialog1(() => ({ isOpen: false }))}
        // onChange={(e) => handleChange(e)}
        // submit={submitMeeting}
        // state={dialog}
        />
        <SellersTable />
        {/* <Table columns={columns} data={mydata} /> */}
        {/* <DataTable
          columns={column}
          // rowData={mydata}
          rowData={data?.data?.data}
          // loading={isLoading}
          hover
          handleChange={(e) => console.log(e)}
        /> */}
      </CardBody>
    </Card>
  );
}

import React, { useState, useEffect } from "react";

import { REACT_APP_API } from "../../config";

// import 'mdbreact/dist/css/mdb.css';
// import 'bootstrap-css-only/css/bootstrap.min.css';
// import '@fortawesome/fontawesome-free/css/all.min.css';
import { handleDataRequest } from "../network/api";
import { getSellerID } from "../Utils";
import SVG from "react-inlinesvg";
import { ButtonGroup, Button } from "react-bootstrap";
import { sortCaret, toAbsoluteUrl } from "../../_metronic/_helpers";
import { MDBDataTableV5 } from "mdbreact";
import { useIntl } from "react-intl";

const SellersTable = () => {
  const [tableData, setTableData] = useState({ data: [] });
  const intl = useIntl();
  const [qty, setQty] = useState({ count: "" });
  const [dialog, setDialog] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  const [dialog1, setDialog1] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  function onIncrement(event) {
    const target = event.target;
    const value = target.qty;
    console.log("val", value);
    setQty({ ...qty, count: value + 1 });
  }

  //   console.log("id", getSellerID())

  const getData = () => {
    handleDataRequest("GET", `${REACT_APP_API}sellers`)
      .then((res) => {
        setTableData({
          ...tableData,
          data: res.data,
        });
      })
      .catch((err) => {
        console.log(err);
      });
  };

  useEffect(() => {
    getData();
    console.log(" sellers data", tableData);
  }, []);

  const data = {
    columns: [
      {
        label: intl.formatMessage({ id: "SELLERID" }),
        field: "_id",
        sort: "dsc",
        // width: 200
      },
      {
        label: intl.formatMessage({ id: "SELLERNAME" }),
        field: "username",
        sort: "dsc",
        // width: 200
      },
      {
        label: intl.formatMessage({ id: "EMAIL" }),
        field: "email",
        sort: "dsc",
        // width: 200
      },
      //   {
      //     label: 'Qty',
      //     field: 'qty',
      //     sort: 'asc',
      //     // width: 200
      //   },
      {
        label: intl.formatMessage({ id: "CREATEDAT" }),
        field: "date",
        sort: "dsc",
        // width: 200
      },
      //   {
      //     label: 'Seller Name',
      //     field: 'seller_id',
      //     sort: 'asc',
      //     // width: 200
      //   },
      //   {
      //     label: 'Status',
      //     field: 'status',
      //     sort: 'asc',
      //     // width: 200
      //   },
      {
        label: intl.formatMessage({ id: "ACTION" }),
        field: "roles",
        formatter: () => (
          <>
            <ButtonGroup>
              <button
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={(e) => {
                  setDialog({
                    isOpen: true,
                    // ticketId: row.id,
                    // user_id: row.advertiser_id,
                  });
                }}
              >
                {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                <span className="symbol-label">
                  <SVG
                    className="svg-icon menu-icon"
                    style={{ fill: "blue", width: "17px" }}
                    src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                  />
                </span>
                {/* </a> */}

                {/* <span>{data}</span> */}
              </button>
              <button
                className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
                onClick={(e) => {
                  setDialog1({
                    isOpen: true,
                    // ticketId: row.id,
                    // user_id: row.advertiser_id,
                  });
                }}
              >
                {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
                <span className="symbol-label">
                  <SVG
                    className="svg-icon menu-icon"
                    style={{ height: "18px" }}
                    src={toAbsoluteUrl("/media/svg/icons/Design/delete.svg")}
                  />
                </span>
                {/* </a> */}

                {/* <span>{data}</span> */}
              </button>
            </ButtonGroup>
          </>
        ),
      },
    ],
    rows:
      // [
      tableData.data.map((item, index) => {
        // {console.log(item)}
        return {
          _id: `${item._id}`,
          username: `${item.username}`,
          email: `${item.email}`,
          date: "2011/04/25",
          roles: (
            <button
              className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
              onClick={(e) => {
                setDialog({
                  isOpen: true,
                  // ticketId: row.id,
                  // user_id: row.advertiser_id,
                });
              }}
            >
              {/* <a className="" href='#' onClick={() => uiProps.openUserProfile("23526")}> */}
              <span className="symbol-label">
                <SVG
                  className="svg-icon menu-icon"
                  style={{ fill: "blue", width: "17px" }}
                  src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
                />
              </span>
              {/* </a> */}

              {/* <span>{data}</span> */}
            </button>
          ),
        };
      }),
    // {
    //   category_id: '001',
    //   product_name: 'Order 001',
    //   price: '400',
    //   qty: <div style={{cursor:"pointer"}} onClick = {onIncrement} >100</div>,
    //   date: '2011/04/25',
    // },

    // ]
  };

  return (
    <MDBDataTableV5
      hover
      entriesOptions={[10, 20, 25]}
      entries={10}
      pagesAmount={10}
      data={data}
      searchTop
      searchBottom={false}
    />
  );
};

export default SellersTable;

/* eslint-disable no-restricted-imports */
import {
  TextField,
} from "@material-ui/core";
import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";
export function DeleteSellerDialog({ show, onHide, updateStatus }) {
  const [rejectReason, setRejectReason] = useState();

  const intl = useIntl();
  return (
    <Modal
    centered
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg"> {intl.formatMessage({ id: "Delete Confirmation" })}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <span>Are you sure to permanently delete this seller?</span>
        {/* <TextField
          label={intl.formatMessage({ id: "" })}
          className="w-100"
          defaultValue={rejectReason}
          onChange={(e) => setRejectReason(e.target.value)}
        /> */}

        {/* {isLoading && <span>Product is deleting...</span>} */}
      </Modal.Body>
      <Modal.Footer>
        <div>
          <button
            type="button"
            onClick={onHide}
            className="btn btn-light btn-elevate"
          >
            {intl.formatMessage({ id: "Cancel" })}
          </button>
          <> </>
          <button
            type="button"
            onClick={onHide}
            // onClick={() => {
            //   updateStatus('rejected', rejectReason);
            // }}
            className="btn btn-delete btn-success"
          >
            {intl.formatMessage({ id: "Confirm" })}
          </button>
        </div>
      </Modal.Footer>
    </Modal>
  );
}

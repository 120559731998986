import React from "react";
import { useSubheader } from "../../_metronic/layout";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../_metronic/_partials/controls";
import { useIntl } from "react-intl";
import {SettingForm} from "../components/SettingForm"

export const MyPage = () => {
  const intl = useIntl();
  // const suhbeader = useSubheader();
  // suhbeader.setTitle("My Custom title");

  return ( <Card>
  <CardHeader title={intl.formatMessage({ id: "Settings" })}>
    <CardHeaderToolbar>
      {/* <button type="button" className="btn btn-primary">
        {intl.formatMessage({ id: "Add new" })}
      </button> */}
    </CardHeaderToolbar>
  </CardHeader>
  <CardBody>
   {/* <SettingForm /> */}
  </CardBody>
</Card>
  )
};

import axios from "axios";

import { REACT_APP_API } from "../../../../config";

export const LOGIN_URL = `${process.env.REACT_APP_API_URL}/auth/login`;
export const REGISTER_URL = "api/auth/register";
export const REQUEST_PASSWORD_URL = `${process.env.REACT_APP_API_URL}/api/auth/seller/forgot-password`;
export const ME_URL = `${process.env.REACT_APP_API_URL}/auth/login`;

export function login(email, password) {
  return axios.post(LOGIN_URL, { email, password });
}

export function register(email, fullname, username, password) {
  return axios.post(REGISTER_URL, { email, fullname, username, password });
}

export function requestPassword(email) {
  return axios.put(`${REACT_APP_API}auth/seller/forgot-password/`, { email });
}

export function resetPassword(resetLink, newPass) {
  return axios.put(`${REACT_APP_API}auth/seller/reset-password/`, {
    resetLink,
    newPass,
  });
}

export function firstPassword(id, newPass) {
  return axios.put(`${REACT_APP_API}sellers/passwordchange/${id}`, {
    password: newPass,
    status: "approved",
  });
}

export function getUserByToken() {
  // Authorization head should be fulfilled in interceptor.
  return axios.get(ME_URL);
}

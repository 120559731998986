import React, { useState, useEffect } from "react";
import { Modal, Spinner } from "react-bootstrap";
import { TextField } from "@material-ui/core";
import { Button } from "react-bootstrap";
import { makeStyles } from "@material-ui/core";
// import { makeStyles } from "@material-ui/core/styles";
import SaveIcon from "@material-ui/icons/Save";
import Select from "react-select";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { Nav, Tab } from "react-bootstrap";
// import { useTranslation } from "react-i18next";
const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
}));

// const options = [
//   { value: "RECEIVED", label: "RECEIVED" },
//   { value: "ACCEPTED", label: "ACCEPTED" },
//   { value: "PACKED", label: "PACKED" },
//   { value: "SHIPPED", label: "SHIPPED" },
//   { value: "CANCELLED", label: "CANCELLED" },
// ];

export default function StatusChangeModal({
  showModal,
  hideModal,
  onSubmit,
  loading,
  Fields = "Status",
  uniqueStatus,
}) {
  //   const { t } = useTranslation(["common", "ordermanagement"]);
  const [status, setstatus] = useState(null);
  const [showcancelled, setshowcancelled] = useState(false);
  const [TruckingNumber, setTruckingNumber] = useState(null);
  const [LogisticsCompanyName, setLogisticsCompanyName] = useState(null);
  const classes = useStyles();
  const [key, setKey] = useState("");

  const handleChange = (_key) => {
    // setstatus(selectedOption);
    setKey(_key);
    console.log(key);
    if (_key === "SHIPPED") {
      setshowcancelled(true);
    } else {
      setshowcancelled(false);
    }
  };
  const TextChange = (e) => {
    setTruckingNumber(e.target.value);
  };
  var options = [];
  options = returnStatus(uniqueStatus);

  function returnStatus(cellContent) {
    if (cellContent === "RECEIVED") {
      return [
        { value: "ACCEPTED", label: `受注確認済み` },
        // { value: "CANCELLED", label: `${t("common:status.CANCELLED")}` },
      ];
    } else if (cellContent === "ACCEPTED") {
      return [
        // { value: "PACKED", label: `${t("common:status.PACKED")}` },
        // { value: "CANCELLED", label: `${t("common:status.CANCELLED")}` },
        { value: "SHIPPED", label: `発送済み` },
      ];
    }
  }

  console.log(TruckingNumber);

  return (
    <div>
      <Modal
        show={showModal}
        onHide={() => hideModal()}
        backdrop="static"
        keyboard={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>発送を完了する</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {/* Body */}
          <div>
            {/*{uniqueStatus && (
              <Select
                options={options}
                onChange={handleChange}
                placeholder={t("ordermanagement:modals.select")}
              />
            )}*/}

            <CardHeaderToolbar style={{ marginLeft: "30px" }}>
              <div>
                <Tab.Container defaultActiveKey={key}>
                  {uniqueStatus && options && (
                    <div>
                      <Nav
                        as="ul"
                        onSelect={(_key) => handleChange(_key)}
                        className="nav nav-pills nav-pills-sm nav-primary-15"
                      >
                        <h3>
                          <b>クリックしてください。 {"->"}</b>
                        </h3>
                        {options.map((x) => (
                          <Nav.Item className="nav-item" as="li">
                            <Nav.Link
                              eventKey={x.value}
                              className={`nav-link py-2 px-4 ${
                                key === `${x.value}` ? "active" : ""
                              }`}
                            >
                              {x.label}
                            </Nav.Link>
                          </Nav.Item>
                        ))}
                      </Nav>
                    </div>
                  )}
                  {showcancelled && (
                    <TextField
                      id="standard-basic"
                      label="Trucking Number"
                      fullWidth
                      onChange={TextChange}
                      variant="outlined"
                      style={{ marginTop: "30px", height: 44 }}
                      multiline
                      rows={2}
                    />
                  )}
                </Tab.Container>
              </div>
            </CardHeaderToolbar>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <div style={{ marginRight: "1rem" }}>
              <Button variant="primary  btn-block" onClick={() => hideModal()}>
                キャンセル
              </Button>
            </div>
            <div>
              <Button
                variant="primary  btn-block"
                disabled={!key}
                onClick={() =>
                  onSubmit({ key, TruckingNumber, LogisticsCompanyName })
                }
              >
                {loading && (
                  <Spinner animation="border" role="status" size="sm">
                    <span className="sr-only">Loading...</span>
                  </Spinner>
                )}
                送信 <SaveIcon fontSize={"small"} />
              </Button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    </div>
  );
}

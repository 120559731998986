import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { connect } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import { useHistory } from "react-router-dom";

import { createseller } from "../../network/api";



const initialValues = {
    username: "",
    email: "",
    password: "",
};

function CreateSeller(props) {
    const history = useHistory();
    const { intl } = props;
    const [loading, setLoading] = useState(false);
    const backToSellersList = () => {
        history.push("/sellers");
    };
    const LoginSchema = Yup.object().shape({
        username: Yup.string()
            .required()

        ,
        email: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required()

        ,
        password: Yup.string()
            .min(3, "Minimum 3 symbols")
            .max(50, "Maximum 50 symbols")
            .required()
        ,
    });

    const enableLoading = () => {
        setLoading(true);
    };

    const disableLoading = () => {
        setLoading(false);
    };

    const getInputClasses = (fieldname) => {
        if (formik.touched[fieldname] && formik.errors[fieldname]) {
            return "is-invalid";
        }

        if (formik.touched[fieldname] && !formik.errors[fieldname]) {
            return "is-valid";
        }

        return "";
    };

    const formik = useFormik({
        initialValues,
        validationSchema: LoginSchema,
        onSubmit: (values, { setStatus, setSubmitting }) => {
            enableLoading();
            setTimeout(() => {
                createseller({ username: values.username, email: values.email, password: values.password })
                    .then(({ data: { accessToken } }) => {
                        disableLoading();
                        setSubmitting(false);
                        backToSellersList();
                        // props.login(accessToken);

                    })
                    .catch(() => {
                        setStatus(
                        );
                    })
                    .finally(() => {
                        disableLoading();
                        setSubmitting(false);
                        backToSellersList();
                    });
            }, 1000);
        },
    });

    return (
        <div className="login-form login-signin" id="kt_login_signin_form" >

            <div className=" text-center mb-10 mb-lg-20" >
                <h3 className="font-size-h1">
                    <FormattedMessage id="CREATESELLER" />
                </h3>

            </div>

            <div style={{ marginLeft: "430px" }}>
                <form
                    onSubmit={formik.handleSubmit}
                    className="form fv-plugins-bootstrap fv-plugins-framework"
                >
                    {formik.status && (
                        <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
                            <div className="alert-text font-weight-bold">{formik.status}</div>
                        </div>
                    )}
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder=" Enter Username"
                            type="text"
                            style={{ width: "400px" }}
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "eusername"
                            )}`}
                            name="username"
                            {...formik.getFieldProps("username")}
                        />
                        {formik.touched.email && formik.errors.username ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.username}</div>
                            </div>
                        ) : null}
                    </div>

                    <div className="form-group  text-center fv-plugins-icon-container">
                        <input
                            placeholder="Enter Email"
                            type="text"
                            style={{ width: "400px" }}
                            className={`form-control  form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "email"
                            )}`}
                            name="email"
                            {...formik.getFieldProps("email")}
                        />
                        {formik.touched.email && formik.errors.email ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.email}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group fv-plugins-icon-container">
                        <input
                            placeholder=" Enter Password"
                            type="password"
                            style={{ width: "400px" }}
                            className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses(
                                "password"
                            )}`}
                            name="password"
                            {...formik.getFieldProps("password")}
                        />
                        {formik.touched.password && formik.errors.password ? (
                            <div className="fv-plugins-message-container">
                                <div className="fv-help-block">{formik.errors.password}</div>
                            </div>
                        ) : null}
                    </div>
                    <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
                        {/* <Link
            to="/auth/forgot-password"
            className="text-dark-50 text-hover-primary my-3 mr-2"
            id="kt_login_forgot"
          >
            <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
          </Link> */}
                        <button
                            id="kt_login_signin_submit"
                            type="submit"
                            disabled={formik.isSubmitting}
                            className={`btn btn-primary font-weight-bold px-9 py-4 my-3`}
                        >
                            <span>
                                <FormattedMessage id="AUTH.GENERAL.SUBMIT_BUTTON" />
                            </span>
                            {loading && <span className="ml-3 spinner spinner-white"></span>}
                        </button>
                    </div>
                </form>
            </div>
            {/*end::Form*/}
        </div>
    );
}

export default CreateSeller;

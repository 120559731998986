import { useEffect, useState } from "react";
import { useIntl } from "react-intl";

import SVG from "react-inlinesvg";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { toAbsoluteUrl } from "../../../_metronic/_helpers";

import ProductsTable from "../../components/ProductsTable";

import "../Search.css";

import { Search } from "react-bootstrap-table2-toolkit";
import moment from "moment";
import axios from "axios";
import { InventoryModal } from "./InventoryModal";
import SuccessMessage from "../../../../src/_metronic/layout/components/SuccessMessage";

import { REACT_APP_API } from "../../../config";

export default function ProductCard() {
  const intl = useIntl();

  const [data, setData] = useState([]);
  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  const [isEditModal, setIsEditModal] = useState(false);
  const [ID, setID] = useState("");
  const [InventoryData, setInventoryData] = useState([]);

  const fetchData = () => axios.get(`${REACT_APP_API}seller/products/`);

  const fetchInventory = (id) => axios.get(`${REACT_APP_API}products/${id}`);
  const fetchLocations = () => axios.get(`${REACT_APP_API}locations`);

  const getInventory = async (id) => {
    var dd = await fetchInventory(id);
    console.log(dd.data.inventory_levels);
    // setInventoryData(dd.data.inventory_levels);
    var { data: locations } = await fetchLocations();

    const array = dd.data.inventory_levels.map((obj) => {
      for (let i = 0; i < locations.locations.length; i++) {
        if (locations.locations[i].id === obj.location_id) {
          return { ...obj, ...{ location_name: locations.locations[i].name } };
        }
      }
    });
    setInventoryData(array);
    setID(id);
    setIsEditModal(true);
  };

  const onClose = () => {
    console.log("clicked");
    setSuccess(false);
  };

  const updateProduct = async (obj) => {
    console.log(obj);

    var res = await axios.post(
      `${process.env.REACT_APP_API}products/${ID}`,
      obj
    );

    ff();
    setIsEditModal(false);
    setSuccessMessage("Stock Quantity updated successfully");
    setSuccess(true);
  };

  const ff = async () => {
    var dd = await fetchData();
    console.log(dd.data.data);
    setData(dd.data.data);
  };

  useEffect(() => {
    ff();
  }, []);

  const { SearchBar } = Search;

  const columns = [
    // {
    //   id: "col1",
    //   label: "カテゴリー",
    //   field: "productId",
    //   accessor: (j) => j.product_id,
    //   sort: "asc",
    //   // width: 200
    // },
    {
      id: "col2",
      label: "商品名",
      field: "product_name",
      accessor: (j) => j.name,
      sort: "asc",
      // width: 200
    },
    {
      id: "col3",
      label: "売値(税抜)",
      field: "price",
      sort: "asc",
      accessor: (j) => j.price,
      // width: 200
    },
    {
      id: "col4",
      label: "在庫数",
      field: "qty",
      sort: "asc",
      accessor: (j) => j.stock_quantity,
      // width: 200
    },
    {
      id: "col5",
      label: "商品追加日時",
      field: "date",
      accessor: (j) => j.createdAt,
      sort: "asc",
      Cell: (e) => moment(`${e.value}`).format("YYYY-MM-DD"),
      // width: 200
    },
    {
      id: "col6",
      label: "アクション",
      field: "_id",
      accessor: (j) => j._id,
      sort: "asc",
      Cell: (e) => (
        <button
          className="btn btn-icon btn-light btn-hover-primary btn-sm mx-3"
          onClick={() => getInventory(`${e.value}`)}
        >
          <span className="symbol-label">
            <SVG
              className="svg-icon menu-icon"
              style={{ fill: "blue", width: "17px" }}
              src={toAbsoluteUrl("/media/svg/icons/Design/Edit.svg")}
            />
          </span>
        </button>
      ),
      // width: 200
    },
  ];

  return (
    <Card style={{ width: "1195px", display: "center" }}>
      {success && <SuccessMessage message={successMessage} onClose={onClose} />}
      <InventoryModal
        state={InventoryData}
        show={isEditModal}
        onHide={() => setIsEditModal(false)}
        updateProduct={updateProduct}
      />
      <CardHeader title={intl.formatMessage({ id: "PRODUCTSLIST" })}>
        <CardHeaderToolbar></CardHeaderToolbar>
      </CardHeader>
      <CardBody>
        <ProductsTable columns={columns} data={data} />
      </CardBody>
    </Card>
  );
}

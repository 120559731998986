import { request, getrequest } from "./api";
import { handleRequestGet } from "./api";

export const getSellers = () => getrequest({ url: "sellers", type: "GET" });
export const getOrders = () =>
  getrequest({ url: `seller/orders/`, type: "GET" });

export const getProducts = () =>
  getrequest({ url: "seller/products/", type: "GET" });

export const getShippedOrders = () =>
  getrequest({ url: "seller/orders/?shipmentStatus=SHIPPED", type: "GET" });

export const getNewOrders = () =>
  getrequest({ url: "seller/orders/?shipmentStatus=RECEIVED", type: "GET" });

import React, { useState, useEffect } from "react";
import "antd/dist/antd.css";
import { Button, Form, Select, Divider } from "antd";
import { useSelector, useDispatch } from "react-redux";
import axios from "axios";
import { useIntl } from "react-intl";
import { actions } from "../../modules/Auth/_redux/authRedux";
import moment from "moment";
import { REACT_APP_API } from "../../../config";
import { useSnackbar } from "notistack";

export default function ProfileCard() {
  const intl = useIntl();
  const dispatch = useDispatch();
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const { user } = useSelector((state) => state.auth);
  const { id } = user;

  const [state, setState] = useState({
    username: "",
    email: "",
    sellerKatakana: "",
    sellerPhoneNumber: null,
    gender: "",
    dob: "",
    sellerPostalCode: "",
    sellerAddress: "",
    disabilityCategory: "",
    disabilityCertificate: "",
    guardianName: "",
    guardianKatakana: "",
    guardianGender: "",
    relationToSeller: "",
    livingStatus: "",
    guardianPostalCode: "",
    guardianAddress: "",
    guardianPhoneNumber: "",
    guardianEmailAddress: "",
  });

  const fetchData = () =>
    axios.get(`${process.env.REACT_APP_API}sellers/${id}`);
  const ff = async () => {
    var dd = await fetchData();
    console.log("dd sellers data", dd.data.data);

    setState({
      username: dd.data.data.username,
      email: dd.data.data.email,
      sellerKatakana: dd.data.data.sellerKatakana,
      sellerPhoneNumber: dd.data.data.sellerPhoneNumber,
      gender: dd.data.data.gender,
      dob: dd.data.data.dob,
      sellerPostalCode: dd.data.data.sellerPostalCode,
      sellerAddress: dd.data.data.sellerAddress,
      disabilityCategory: dd.data.data.disabilityCategory,
      disabilityCertificate: dd.data.data.disabilityCertificate,
      guardianName: dd.data.data.guardianName,
      guardianKatakana: dd.data.data.guardianKatakana,
      guardianGender: dd.data.data.guardianGender,
      relationToSeller: dd.data.data.relationToSeller,
      livingStatus: dd.data.data.livingStatus,
      guardianPostalCode: dd.data.data.guardianPostalCode,
      guardianAddress: dd.data.data.guardianAddress,
      guardianPhoneNumber: dd.data.data.guardianPhoneNumber,
      guardianEmailAddress: dd.data.data.guardianEmailAddress,
    });
  };

  const updateTask = async () => {
    let payload = {
      ...state,
      username: state.username,
      email: state.email,
      sellerKatakana: state.sellerKatakana,
      sellerPhoneNumber: state.sellerPhoneNumber,
      gender: state.gender,
      dob: state.dob,
      sellerPostalCode: state.sellerPostalCode,
      sellerAddress: state.sellerAddress,
      disabilityCategory: state.disabilityCategory,
      disabilityCertificate: state.disabilityCertificate,
      guardianName: state.guardianName,
      guardianKatakana: state.guardianKatakana,
      guardianGender: state.guardianGender,
      relationToSeller: state.relationToSeller,
      livingStatus: state.livingStatus,
      guardianPostalCode: state.guardianPostalCode,
      guardianAddress: state.guardianAddress,
      guardianPhoneNumber: state.guardianPhoneNumber,
      guardianEmailAddress: state.guardianEmailAddress,
      updateProfile: true,
    };
    console.log("payyyload", payload);
    await axios.put(`${REACT_APP_API}sellers/${id}`, payload).then((res) => {
      if (res && res.status === 200) {
        ff();
        setState({
          username: "",
          email: "",
          sellerKatakana: "",
          sellerPhoneNumber: "",
          gender: "",
          dob: "",
          sellerPostalCode: "",
          sellerAddress: "",
          disabilityCategory: "",
          disabilityCertificate: "",
          guardianName: "",
          guardianKatakana: "",
          guardianGender: "",
          relationToSeller: "",
          livingStatus: "",
          guardianPostalCode: "",
          guardianAddress: "",
          guardianPhoneNumber: "",
          guardianEmailAddress: "",
        });
        dispatch(actions.updateProfile());
        enqueueSnackbar("生産者詳細が更新されました。", {
          variant: "success",
          onClick: () => {
            closeSnackbar();
          },
        });
      }
    });
  };

  useEffect(() => {
    ff();
  }, []);
   const [error, setError] = useState(false);
   const [error1, setError1] = useState(false);

  return (
    <>
      {/* {intl.formatMessage({ id: "Sellerdetails" })} */}

      <Form method="post" layout="vertical" /*onFinish={onFinish}*/>
        <div className="form-group row">
          <div className="col-lg-12">
            {" "}
            <Divider> {intl.formatMessage({ id: "Seller" })}</Divider>
          </div>
        </div>

        <div className="form-group row">
          <div className="col-lg-3">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Sellername" })}</span>
            </label>
            <input
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) => setState({ ...state, username: e.target.value })}
              value={state.username}
              disabled
            />
          </div>
          <div className="col-lg-3">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "SellerKatakana" })}</span>
            </label>
            <input
              className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => {
                  // setState({ ...state, guardianName: e.target.value })
                  if (
                 // /[ァ-ヴー]+/u.test(e.target.value)
                    /^([\u3000-\u303f\u30a0-\u30ff\uff00-\uff9f\s\-\(\)]+)$/.test(e.target.value) ||
                    e.target.value === ""
                  ) {
                    setError(false)
                   
                  }
                  else if (
                    !/^([\u3000-\u303f\u30a0-\u30ff\uff00-\uff9f\s\-\(\)]+)$/.test(e.target.value) 

                  ) {
                    setError(true)
                  }
                   setState({ ...state, sellerKatakana: e.target.value });
                }}
                value={state.sellerKatakana}

              />
              {error && (
                <p style={{ color: "red" }}> "フリガナ" はカタカナで入力してください。</p>
              )}
          </div>
          <div className="col-lg-3">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Selleremail" })}</span>
            </label>
            <input
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) => setState({ ...state, email: e.target.value })}
              value={state.email}
              disabled
            />
          </div>

          <div className="col-lg-3">
            <label className="col-xl-4 col-lg-4 col-form-label">
              <span>{intl.formatMessage({ id: "Dateofbirth" })}</span>
            </label>

            <input
              type="date"
              placeholder="yyyy-mm-dd"
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) => setState({ ...state, dob: e.target.value })}
              value={moment(`${state.dob}`).format("YYYY-MM-DD")}
              disabled
            />
            {/* <DatePicker
               format="y-MM-dd"
        onChange={(e) => setState({ ...state, dob: e.target.value })}
        value={state.dob}
      /> */}
            {/* <input type="date" name="begin" 
        placeholder="dd-mm-yyyy" value=""
        min="1997-01-01" max="2030-12-31"></input> */}

            {/* <DatePicker
                showTime
                  className={`ant-input form-control form-control-lg form-control-solid `}
                format="YYYY/MM/DD"
                // value={moment(`${state.dob}`)}
                onChange={(e) => setState({ ...state, dob: e.target.value })}
                placeholder="Select date of birth"
              /> */}
            {/* <input
                type="text"
                
                defaultValue={state.dob}
                placeholder={state.dob}
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, dob: e.target.value })}
                value={state.dob}
              /> */}
          </div>
        </div>

        {/* -------------------------------------Second Panel--------------------------------------------- */}

        <div className="form-group row">
          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>
                {intl.formatMessage({ id: "SelleraddressPostalcode" })}
              </span>
            </label>
            <input
              type="number"
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, sellerPostalCode: e.target.value })
              }
              value={state.sellerPostalCode}
            />
          </div>
          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Selleraddress" })}</span>
            </label>
            <input
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, sellerAddress: e.target.value })
              }
              value={state.sellerAddress}
            />
          </div>
          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Sellerphoneno" })}</span>
            </label>
            {/* <PhoneInput
                defaultCountry="US"
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, sellerPhoneNumber: e.target.value })}
                value={state.sellerPhoneNumber} /> */}
            <input
              type="number"
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, sellerPhoneNumber: e.target.value })
              }
              value={state.sellerPhoneNumber}
            />
          </div>
        </div>

        {/* -------------------------------------third Panel--------------------------------------------- */}
        <div className="form-group row">
          {/* <div className="col-lg-4">
            <label className="col-xl-4 col-lg-4 col-form-label">
              <span>{intl.formatMessage({ id: "CREATEDAT" })}</span>
            </label>
            <input
              className={`form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, createdAt: e.target.value })
              }
              value={moment(`${state.createdAt}`).format("YYYY/MM/DD")}
              disabled
            />
          </div> */}
          <div className="col-lg-4">
            <label className="col-xl-4 col-lg-4 col-form-label">
              <span>{intl.formatMessage({ id: "Gender" })}</span>
            </label>
            <select
              className={`ant form-control form-control-lg form-control-solid `}
              id="lang"
              onChange={(e) => setState({ ...state, gender: e.target.value })}
              value={state.gender}
            >
              <option value=" ">
                {" "}
                {intl.formatMessage({ id: "selectgender" })}
              </option>
              <option value="M">{intl.formatMessage({ id: "male" })}</option>
              <option value="F">{intl.formatMessage({ id: "female" })}</option>
            </select>
            {/* <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state, gender: e.target.value })}
                value={state.gender}
              /> */}
          </div>
          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Disabilitycategory" })}</span>
            </label>
            <select
              className={`ant form-control form-control-lg form-control-solid `}
              id="lang"
              onChange={(e) =>
                setState({ ...state, disabilityCategory: e.target.value })
              }
              value={state.disabilityCategory}
            >
              <option>
                {" "}
                {intl.formatMessage({ id: "selectdisablecategory" })}
              </option>
              <option value="physical">
                {intl.formatMessage({ id: "physical" })}
              </option>
              <option value="intellectual">
                {intl.formatMessage({ id: "intellectual" })}
              </option>
              <option value="mental">
                {intl.formatMessage({ id: "mental" })}
              </option>
              <option value="none">{intl.formatMessage({ id: "none" })}</option>
            </select>
          </div>
          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Disabilitycertificate" })}</span>
            </label>
            <select
              className={`ant form-control form-control-lg form-control-solid `}
              id="lang"
              onChange={(e) =>
                setState({ ...state, disabilityCertificate: e.target.value })
              }
              value={state.disabilityCertificate}
            >
              <option value=" ">
                {intl.formatMessage({ id: "selectdisabilitycertificate" })}
              </option>
              <option value="Yes">{intl.formatMessage({ id: "yes" })}</option>
              <option value="No">{intl.formatMessage({ id: "No" })}</option>
            </select>
          </div>
        </div>

        {/* <div className="form-group row">
          
        </div> */}

        {/* -------------------------------------fourth Panel--------------------------------------------- */}
        <div className="col-lg-12">
          {" "}
          <Divider>
            {intl.formatMessage({ id: "SellerGuardianDetails" })}
          </Divider>
        </div>
        <div className="form-group row">
          <div className="col-lg-3">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Nameofguardian" })}</span>
            </label>
            <input
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, guardianName: e.target.value })
              }
              disabled
              value={state.guardianName}
            />
          </div>
          <div className="col-lg-3">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "GuardianKatakana" })}</span>
            </label>
            <input
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) => {
                  // setState({ ...state, guardianName: e.target.value })
                  if (
                    // /[ァ-ヴー]+/u.test(e.target.value)
                    /^([\u3000-\u303f\u30a0-\u30ff\uff00-\uff9f\s\-\(\)]+)$/.test(e.target.value) ||
                    e.target.value === ""
                    
                  ) {
                    setError1(false)
                    
                  }
                  else if (
                    !/^([ア-ン]+)$/.test(e.target.value) 

                  ) {
                    setError1(true)
                  }
                  setState({ ...state, guardianKatakana: e.target.value });
                }}
                // onChange={(e) => setState({ ...state, guardianName: e.target.value })}
                value={state.guardianKatakana}
              />
                 {error1 && (
                <p style={{ color: "red" }}> "フリガナ" はカタカナで入力してください。</p>
              )}

          </div>
          <div className="col-lg-3">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Parentemail" })}</span>
            </label>
            <input
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, guardianEmailAddress: e.target.value })
              }
              value={state.guardianEmailAddress}
            />
          </div>

          <div className="col-lg-3">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Parentphone" })}</span>
            </label>
            <input
              type="number"
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, guardianPhoneNumber: e.target.value })
              }
              value={state.guardianPhoneNumber}
            />
          </div>
        </div>

        {/* -------------------------------------fifth Panel--------------------------------------------- */}

        <div className="form-group row">
          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Livingstatus" })}</span>
            </label>
            <select
              className={`ant form-control form-control-lg form-control-solid `}
              id="lang"
              onChange={(e) =>
                setState({ ...state, livingStatus: e.target.value })
              }
              value={state.livingStatus}
            >
              <option value=" ">
                {intl.formatMessage({ id: "selectlivingstatus" })}
              </option>
              <option value="separately">
                {intl.formatMessage({ id: "livingseparately" })}
              </option>
              <option value="together">
                {intl.formatMessage({ id: "livingtogether" })}
              </option>
            </select>
            {/* <input
                className={`ant-input form-control form-control-lg form-control-solid `}
                onChange={(e) => setState({ ...state,livingStatus: e.target.value })}
                value={state.livingStatus}
              /> */}
          </div>

          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Postalcodeguardian" })}</span>
            </label>
            <input
              type="number"
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, guardianPostalCode: e.target.value })
              }
              value={state.guardianPostalCode}
              disabled={state.livingStatus === "separately" ? false : true}
            />
          </div>
          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Addressguardian" })}</span>
            </label>
            <input
              className={`ant-input form-control form-control-lg form-control-solid `}
              onChange={(e) =>
                setState({ ...state, guardianAddress: e.target.value })
              }
              value={state.guardianAddress}
              disabled={state.livingStatus === "together"}
            />
          </div>
        </div>

        {/* -------------------------------------Sixth Panel--------------------------------------------- */}

        <div className="form-group row">
          <div className="col-lg-4">
            <label className="col-xl-4 col-lg-4 col-form-label">
              <span>{intl.formatMessage({ id: "Gender" })}</span>
            </label>
            <select
              className={`ant form-control form-control-lg form-control-solid `}
              id="lang"
              onChange={(e) =>
                setState({ ...state, guardianGender: e.target.value })
              }
              value={state.guardianGender}
            >
              <option value=" ">
                {intl.formatMessage({ id: "selectgender" })}
              </option>
              <option value="M">{intl.formatMessage({ id: "male" })}</option>
              <option value="F">{intl.formatMessage({ id: "female" })}</option>
            </select>
          </div>
          <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Relationshipseller" })}</span>
            </label>
            <select
              className={`ant form-control form-control-lg form-control-solid `}
              id="lang"
              onChange={(e) =>
                setState({ ...state, relationToSeller: e.target.value })
              }
              value={state.relationToSeller}
            >
              <option value=" ">
                {intl.formatMessage({ id: "selectrelationshipseller" })}
              </option>
              <option value="father">
                {intl.formatMessage({ id: "father" })}
              </option>
              <option value="mother">
                {intl.formatMessage({ id: "mother" })}
              </option>
              <option value="grandfather">
                {intl.formatMessage({ id: "grandfather" })}
              </option>
              <option value="grandmother">
                {intl.formatMessage({ id: "grandmother" })}
              </option>
              <option value="other">
                {intl.formatMessage({ id: "other" })}
              </option>
            </select>
          </div>

          {/* <div className="col-lg-4">
            <label className="col col-form-label">
              <span>{intl.formatMessage({ id: "Remarks" })}</span>
            </label>
            <textarea
              className={`ant form-control form-control-lg form-control-solid `}
              id="lang"
              onChange={(e) => setState({ ...state, remarks: e.target.value })}
              value={state.remarks}
            ></textarea>
          </div> */}
        </div>
      </Form>
      <div className="row">
        <button
          type="button"
          className="btn btn-delete btn-primary"
          style={{ marginLeft: "5px" }}
          disabled={
            !state.sellerPhoneNumber ||
            !state.gender ||
            !state.dob ||
            !state.sellerPostalCode ||
            !state.sellerAddress ||
            !state.sellerKatakana ||
            !state.guardianKatakana ||
            !state.disabilityCategory ||
            !state.disabilityCertificate ||
            !state.guardianName ||
            !state.guardianGender ||
            !state.relationToSeller ||
            !state.livingStatus ||
            !state.guardianPhoneNumber ||
            !state.guardianEmailAddress ||
             error1 ||
             error
          }
          onClick={(e) => {
            e.preventDefault();
            console.log("sssseeeee");
            updateTask({ data: state });
          }}
          // onClick={updateTask}
          // onClick={(e) => {
          //   e.preventDefault();
          //   console.log("ssss");
          //   updateProduct({ data: ownState });
          // }}
        >
          {intl.formatMessage({ id: "update" })}
        </button>
      </div>
    </>
  );
}

/* eslint-disable no-restricted-imports */

import React, { useState, useEffect, useQuery } from "react";
import { Modal } from "react-bootstrap";
import { useIntl } from "react-intl";

export function InventoryModal({ show, onHide, state, updateProduct }) {
  const [ownState, setOwnState] = useState([]);

  const updateFieldChanged = (index) => (e) => {
    console.log("index: " + index);
    console.log("property name: " + e.target.name);
    let newArr = [...ownState]; // copying the old datas array
    newArr[index].available = Number(e.target.value); // replace e.target.value with whatever you want to change it to

    setOwnState(newArr); // ??
  };

  useEffect(() => {
    setOwnState(state);
  }, [state]);

  const intl = useIntl();

  return (
    <Modal
      centered
      show={show}
      onHide={onHide}
      aria-labelledby="example-modal-sizes-title-lg"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-modal-sizes-title-lg"> 商品詳細</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={() => updateProduct()}>
          <div className="row">
            <label className="col-xl-6 col-lg-6 col-form-label">
              <h5>ロケーション名</h5>
            </label>

            <label className="col-xl-6 col-lg-6 col-form-label">
              <h5>購入可能商品</h5>
            </label>
          </div>
          {(ownState || []).map((item, index) => (
            <React.Fragment>
              <div className="row">
                <label className="col-xl-6 col-lg-6 col-form-label">
                  {" "}
                  {item.location_name}
                </label>
                <div className="col-lg-6 col-xl-6">
                  <div>
                    <input
                      type="number"
                      className={`form-control form-control-lg form-control-solid `}
                      name="available"
                      value={item.available}
                      onChange={updateFieldChanged(index)}
                    />
                    <br />
                  </div>
                </div>
              </div>
            </React.Fragment>
          ))}
          <div
            className="row"
            style={{
              display: "flex",
              flexDirection: "row",
              justifyContent: "flex-end",
            }}
          >
            <button
              type="button"
              onClick={onHide}
              className="btn btn-light btn-elevate float-end"
            >
              キャンセル
            </button>

            <button
              type="button"
              className="btn btn-delete btn-primary float-end"
              style={{ marginLeft: "5px" }}
              onClick={(e) => {
                e.preventDefault();
                updateProduct({ data: ownState });
              }}
            >
              更新
            </button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  );
}

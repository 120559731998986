import React from "react";
import { ListGroup } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { Modal, Row, Col, Container, Button } from "react-bootstrap";

const PriceModal = (props) => {
  return (
    <div>
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">注文詳細</Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <ListGroup as="ol" numbered>
              注文個別詳細
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">商品ベース金額</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.baseOrderPrice}
                  </Badge>
                </h3>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">商品値引額</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.item_discount}
                  </Badge>
                </h3>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">注文数</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    {props.price.quantity}
                  </Badge>
                </h3>
              </ListGroup.Item>
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">値引後商品金額</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.item_price * props.price.quantity}
                  </Badge>
                </h3>
              </ListGroup.Item>
              {/* Seller Order Profit */}
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">生産者販売利益</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥
                    {1100 * props.price.quantity -
                      props.price.quantity * props.price.item_discount}
                  </Badge>
                </h3>
              </ListGroup.Item>
              <hr />
              <hr />
              {/* 注文全体詳細 */}
              {/* <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">小計</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.subtotal}
                  </Badge>
                </h3>
              </ListGroup.Item> */}
              {/* <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">値引合計</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.total_discount}
                  </Badge>
                </h3>
              </ListGroup.Item> */}
              {/* <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">送料</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.total_shipping}
                  </Badge>
                </h3>
              </ListGroup.Item> */}
              {/* <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">税金</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.total_tax}
                  </Badge>
                </h3>
              </ListGroup.Item> */}
              注文ID: {props.price.order_id}
              <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">この注文の生産者別割引額合計</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.myDiscount}
                  </Badge>
                </h3>
              </ListGroup.Item>
              {/* <ListGroup.Item
                as="li"
                className="d-flex justify-content-between align-items-start"
              >
                <div className="ms-2 me-auto">
                  <div className="fw-bold">合計</div>
                </div>
                <h3>
                  <Badge variant="primary" pill>
                    ¥{props.price.total_order_price}
                  </Badge>
                </h3>
              </ListGroup.Item> */}
            </ListGroup>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>キャンセル</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default PriceModal;

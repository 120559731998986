import React from "react";

import { Modal, Row, Col, Container, Button } from "react-bootstrap";

const CustomerModal = (props) => {
  return (
    <div>
      <Modal {...props} aria-labelledby="contained-modal-title-vcenter">
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            購入者詳細
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className="show-grid">
          <Container>
            <Row>
              <Col xs={6}>購入者名</Col>
              <Col xs={6}>
                {props.customer.customerName
                  ? props.customer.customerName.split(" ")[1]
                    ? props.customer.customerName.split(" ")[1] +
                      " " +
                      props.customer.customerName.split(" ")[0]
                    : props.customer.customerName.split(" ")[0]
                  : "–––"}
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}>購入者電話番号</Col>
              <Col xs={6}>
                {props.customer.customerPhone
                  ? props.customer.customerPhone
                  : "–––"}{" "}
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}>購入者住所</Col>
              <Col xs={6}>
                {props.customer.customerAddress
                  ? props.customer.customerAddress
                  : "–––"}{" "}
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}>追跡番号</Col>
              <Col xs={6}>
                {props.customer.truckingNumber
                  ? props.customer.truckingNumber
                  : "–––"}{" "}
              </Col>
            </Row>
            <br />
            <Row>
              <Col xs={6}>運送会社名</Col>
              <Col xs={6}>
                {props.customer.logisticsCompany
                  ? props.customer.logisticsCompany
                  : "–––"}{" "}
              </Col>
            </Row>
          </Container>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={props.onHide}>キャンセル</Button>
        </Modal.Footer>
      </Modal>
    </div>
  );
};

export default CustomerModal;

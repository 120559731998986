import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { useQuery } from "react-query";
import SVG from "react-inlinesvg";
import React from "react";
import {
  Card,
  CardBody,
  CardHeader,
  CardHeaderToolbar,
} from "../../../_metronic/_partials/controls";
import { DataTable } from "../../components/DataTable";
import { sortCaret, toAbsoluteUrl } from "../../../_metronic/_helpers";
import { query } from "../../network";
import { DeleteSellerDialog } from "../../deleteseller-dialog/DeleteSellerDialog";
import { Filter } from "../../components/Filter";
import { ButtonGroup, Button, Alert } from "react-bootstrap";
import { Badge } from "react-bootstrap";
import { request, getrequest } from "../../network/api";
import axios from "axios";
import moment from "moment";
import OrdersTable from "../../components/OrderTable";
import Search from "../Search";
import { BrowserRouter, Route, Link } from "react-router-dom";
import StatusChangeModal from "./StatusChangeModal";
import ShippedModal from "./ShippedModal";
import InfoModal from "./InfoModal";
import { set } from "object-path";
import { useSelector } from "react-redux";
import { REACT_APP_API } from "../../../config";
import { EyeOutlined } from "@ant-design/icons";
import CustomerModal from "./CustomerModal";
import EditShippedModal from "./EditShippedModal";
import PriceModal from "./PriceModal";
export default function OrderCard() {
  const intl = useIntl();
  const { user } = useSelector((state) => state.auth);
  const [data, setData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [ShippingModalShow, setShippingModalShow] = useState(false);
  const [customer, setCustomer] = useState({
    customerName: "",
    customerAddress: "",
    customerPhone: "",
    truckingNumber: "",
    logisticsCompany: "",
  });

  const [price, setPrice] = useState({
    order_id: null,
    subtotal: 0,
    total_discount: 0,
    total_shipping: 0,
    total_tax: 0,
    total_order_price: 0,
    item_discount: 0,
    item_price: 0,
    baseOrderPrice: 0,
    myDiscount: 0,
    quantity: 0,
  });
  const [priceShow, setPriceShow] = useState(false);

  // const { data, isLoading, isError } = useQuery(
  //   "sellers",
  //   query.getSellers
  // );
  // useEffect(() => {
  //   console.log(data?.data);
  // }, [data])
  const [dialog, setDialog] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  const [dialog1, setDialog1] = useState({
    isOpen: false,
    // user_id: "",
    // ticketId: "",
    // id: "",
    // note: "",
    // image: "",
    // viewOnly: false,
  });

  const [showStatusChangeModal1, setShowStatusChangeModal1] = useState(false);
  const [showShippedModal, setShowShippedModal] = useState(false);
  const [loader, setloader] = useState(false);
  const [StatusMatch1, setStatusMatch1] = useState([]);
  const [Id, setId] = useState("");

  const fetchData = () => axios.get(`${REACT_APP_API}seller/orders/`);

  const ff = async () => {
    var dd = await fetchData();
    console.log(dd.data.data);
    setData(dd.data.data);
  };

  useEffect(() => {
    ff();

    // console.log(data);
  }, []);

  // console.log(showStatusChangeModal1);
  // console.log(data);

  const clickReceived = (e, id) => {
    e.preventDefault();
    setId(id);
    setStatusMatch1("RECEIVED");
    setShowStatusChangeModal1(true);
  };

  const clickAccepted = (e, id) => {
    e.preventDefault();
    setId(id);
    setStatusMatch1("ACCEPTED");
    setShowShippedModal(true);
  };

  const clickShipped = (e) => {
    e.preventDefault();
    // alert("shipped is clicked");
  };

  const SubmitStatus1 = async (values) => {
    setloader(true);
    const BASE_URLs = `${REACT_APP_API}orders/${Id}`;
    console.log("status will change");
    console.log(Id);
    console.log(values);
    try {
      const obj = await axios.put(BASE_URLs, {
        shipmentStatus: values.key,
        truckingNumber: values.TruckingNumber,
        logisticsCompany: values.LogisticsCompanyName,
      });
      setId("");
      setShowShippedModal(false);
      setShowStatusChangeModal1(false);
      setloader(false);
      ff();
    } catch (err) {
      setId("");
      setShowShippedModal(false);
      setShowStatusChangeModal1(false);
      setloader(false);
    }
  };

  const SubmitStatus2 = async (values) => {
    console.log(values);
    setloader(true);
    const BASE_URLs = `${REACT_APP_API}orders/tracking/${Id}`;
    // console.log("status will change");
    console.log(Id);
    console.log(values);
    try {
      const obj = await axios.put(BASE_URLs, {
        truckingNumber: values.TruckingNumber,
        logisticsCompany: values.LogisticsCompanyName,
      });
      setId("");
      setShippingModalShow(false);
      // setShowStatusChangeModal1(false);
      setloader(false);
      ff();
    } catch (err) {
      setId("");
      setShippingModalShow(false);
      // setShowStatusChangeModal1(false);
      setloader(false);
    }
  };

  const closeStatuChangeModal1 = () => {
    setShowStatusChangeModal1(false);
  };

  const closeShippedModal = () => {
    setShowShippedModal(false);
  };

  const closeEditShippingModal = () => {
    setShippingModalShow(false);
  };

  const columns = [
    {
      id: "col6",
      Header: "発送状況",
      accessor: (j) => j.shipmentStatus,
      sort: "asc",
      Cell: (cellContent) => {
        const Status_Buttons = (status) => {
          if (status === "ACCEPTED") {
            return (
              <Button
                size="sm"
                variant="warning"
                style={{ cursor: "pointer", width: "100px" }}
                onClick={(e) => clickAccepted(e, cellContent.row.original._id)}
              >
                <strong>受注確認済み</strong>
              </Button>
            );
          }

          if (status === "RECEIVED") {
            return (
              <Button
                variant="danger"
                size="sm"
                style={{ cursor: "pointer", width: "100px" }}
                onClick={(e) => clickReceived(e, cellContent.row.original._id)}
              >
                <strong>未処理</strong>
              </Button>
            );
          }

          if (status === "SHIPPED") {
            return (
              <Button
                variant="success"
                size="sm"
                style={{ cursor: "pointer", width: "100px" }}
                onClick={clickShipped}
              >
                <strong>発送済み</strong>
              </Button>
            );
          }
          if (status === "FULFILLED") {
            return (
              <Button
                variant="secondary"
                size="sm"
                style={{ cursor: "pointer", width: "100px" }}
                disabled
              >
                <strong>発送確認済み</strong>
              </Button>
            );
          }
        };
        return Status_Buttons(cellContent.value);
      },
    },
    {
      id: "col5",
      Header: "注文日",
      accessor: (j) => j.orderedAt,
      Cell: (e) => moment(`${e.value}`).format("YYYY-MM-DD HH:mm"),
    },
    { id: "col1", Header: "注文ID", accessor: (j) => j.order_id },
    {
      id: "col22",
      Header: "商品名",
      accessor: (j) => j.product.name,
    },
    { id: "col2", Header: "注文数", accessor: (j) => j.orderQuantity },
    // { id: "col3", Header: "顧客詳細", accessor: (j) => j.customerName },
    {
      id: "col3",
      Header: "購入者",
      accessor: (j) => {
        return (
          <Button
            variant="light"
            style={{ textAlign: "center" }}
            onClick={(e) => {
              setModalShow(true);
              setCustomer({
                customerName: j.customerName,
                customerAddress:
                  j.pinCode +
                  ", " +
                  j.country +
                  ", " +
                  j.prefecture +
                  ", " +
                  j.city +
                  ", " +
                  j.address1 +
                  ", " +
                  j.address2,
                customerPhone: j.phone,
                logisticsCompany: j.logisticsCompany,
                truckingNumber: j.truckingNumber,
              });
            }}
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
    // { id: "col4", Header: "注文金額", accessor: (j) => j.baseOrderPrice },
    // Seller Wise Profit
    {
      id: "col1222287",
      Header: "生産者販売利益",
      accessor: (j) => {
        // const my_discount_obj =
        //   j.sellerWiseDiscount.length !== 0
        //     ? j.sellerWiseDiscount.filter((v) => v.seller === user.username)
        //     : [];
        // return my_discount_obj.length !== 0
        //   ? `¥ ${
        //       1100 * my_discount_obj[0].quantity - my_discount_obj[0].discount
        //     }`
        //   : `¥ ${1100 * my_discount_obj[0].quantity}`;
        return 1100 * j.orderQuantity - j.orderQuantity * j.item_discount;
      },
    },

    //order details
    {
      id: "col13234",
      Header: "注文詳細",
      accessor: (j) => {
        return (
          <Button
            variant="light"
            style={{ textAlign: "center" }}
            onClick={(e) => {
              const my_discount_obj =
                j.sellerWiseDiscount.length !== 0
                  ? j.sellerWiseDiscount.filter(
                      (v) => v.seller === user.username
                    )
                  : [];
              setPriceShow(true);
              setPrice({
                order_id: j.order_id,
                subtotal: j.subtotal ? j.subtotal : 0,
                total_discount: j.total_discount ? j.total_discount : 0,
                total_shipping: j.total_shipping ? j.total_shipping : 0,
                total_tax: j.total_tax ? j.total_tax : 0,
                total_order_price: j.total_order_price
                  ? j.total_order_price
                  : 0,
                item_discount: j.item_discount ? j.item_discount : 0,
                item_price: j.item_price ? j.item_price : 0,
                baseOrderPrice: j.baseOrderPrice ? j.baseOrderPrice : 0,
                myDiscount:
                  my_discount_obj.length !== 0
                    ? my_discount_obj[0].discount
                    : 0,
                quantity: j.orderQuantity,
                myProfit:
                  my_discount_obj.length !== 0
                    ? 1100 * my_discount_obj[0].quantity -
                      my_discount_obj[0].discount
                    : 1100 * my_discount_obj[0].quantity,
              });
            }}
          >
            <EyeOutlined />
          </Button>
        );
      },
    },
    {
      id: "col34",
      Header: "編集",
      accessor: (j) => {
        return (
          <div>
            {j.shipmentStatus === "FULFILLED" ? (
              <Button
                variant="light"
                style={{ textAlign: "center" }}
                onClick={(e) => {
                  e.preventDefault();
                  setId(j._id);
                  console.log(j._id);
                  setShippingModalShow(true);

                  // alert("ASKDASDKASD");
                  // setCustomer({
                  //   customerName: j.customerName,
                  //   customerAddress:
                  //     j.pinCode +
                  //     ", " +
                  //     j.country +
                  //     ", " +
                  //     j.prefecture +
                  //     ", " +
                  //     j.city +
                  //     ", " +
                  //     j.address1 +
                  //     ", " +
                  //     j.address2,
                  //   customerPhone: j.phone,
                  //   logisticsCompany: j.logisticsCompany,
                  //   truckingNumber: j.truckingNumber,
                  // });
                }}
              >
                <EyeOutlined />
              </Button>
            ) : (
              <Button
                variant="light"
                style={{ textAlign: "center" }}
                disabled
                title="Order is not shipped yet"
              >
                <EyeOutlined />
              </Button>
            )}
          </div>
        );
      },
    },
  ];

  console.log(Id);

  return (
    <>
      <CustomerModal
        show={modalShow}
        onHide={() => setModalShow(false)}
        customer={customer}
      />
      <PriceModal
        show={priceShow}
        onHide={() => setPriceShow(false)}
        price={price}
      />
      {ShippingModalShow && (
        <EditShippedModal
          showModal={ShippingModalShow}
          hideModal={closeEditShippingModal}
          onSubmit={SubmitStatus2}
          loading={loader}
          orderId={Id}
        />
      )}
      <Card style={{ width: "1195px", display: "center" }}>
        <CardHeader title="注文一覧">
          {showStatusChangeModal1 && (
            <StatusChangeModal
              showModal={showStatusChangeModal1}
              hideModal={closeStatuChangeModal1}
              onSubmit={SubmitStatus1}
              loading={loader}
              uniqueStatus={StatusMatch1}
            />
          )}
          {showShippedModal && (
            <ShippedModal
              showModal={showShippedModal}
              hideModal={closeShippedModal}
              onSubmit={SubmitStatus1}
              loading={loader}
              uniqueStatus={StatusMatch1}
            />
          )}
          <CardHeaderToolbar>
            {/* <button type="button" className="btn btn-primary">
            {intl.formatMessage({ id: "Add new Seller" })}
            <Link to="/create" />
          </button> */}
          </CardHeaderToolbar>
        </CardHeader>
        <CardBody>
          {/* <Filter/> */}

          <DeleteSellerDialog
            show={dialog1.isOpen}
            onHide={() => setDialog1(() => ({ isOpen: false }))}
            // onChange={(e) => handleChange(e)}
            // submit={submitMeeting}
            // state={dialog}
          />
          <OrdersTable columns={columns} data={data} />

          {/* <Table columns={columns} data={mydata} /> */}
          {/* <DataTable
          columns={column}
          // rowData={mydata}
          rowData={data?.data?.data}
          // loading={isLoading}
          hover
          handleChange={(e) => console.log(e)}
        /> */}
        </CardBody>
      </Card>
    </>
  );
}

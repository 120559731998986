import React, { useMemo } from "react";
import BootstrapTable from "react-bootstrap-table-next";
import paginationFactory, {
  PaginationProvider,
} from "react-bootstrap-table2-paginator";
import { Pagination } from "../../_metronic/_partials/controls";
import * as uiHelpers from "./UIhelpers";
import * as _ from "lodash";
import filterFactory from "react-bootstrap-table2-filter";
import { FormattedMessage } from "react-intl";

const objectHelper = (data) => {
  const ob = {};
  if (data) {
    Object.entries(data).forEach(([k, val]) => {
      if (k === "active" || k === "action_taken") {
        ob[k] = val.filterVal === "true";
      } else ob[k] = val.filterVal;
    });
    return ob;
  }
};

const noDataIndicator = (
  <div className="no-data-indicator">
    <h3>
      {" "}
      <FormattedMessage id="No Data Found" />
    </h3>
  </div>
);

export function DataTable({
  rowData,
  columns,
  loading,
  hover,
  rowEvents,
  paginate,
  handleChange,
}) {
  const paginationOptions = useMemo(() => {
    return {
      custom: true,
      totalSize: paginate?.total_count || 0,
      sizePerPageList: uiHelpers.sizePerPageList,
      sizePerPage: 10,
      page: paginate?.page,
    };
  }, [paginate]);

  //   const { totalCount, listLoading } = currentState;

  return (
    <PaginationProvider pagination={paginationFactory(paginationOptions)}>
      {({ paginationProps, paginationTableProps }) => {
        return (
          <Pagination isLoading={loading} paginationProps={paginationProps}>
         
            <BootstrapTable
              wrapperClasses="table-responsive"
              classes="table table-head-custom table-vertical-center overflow-hidden"
              bootstrap4
              bordered={false}
              keyField="id"
              data={rowData ? rowData : []}
              columns={columns}
              loading={loading}
              hover={hover}
              noDataIndication={loading ? "loading..." : noDataIndicator}
              remote={{ filter: true, pagination: true }}
              onTableChange={(type, newState) => {
                delete newState.data;
                newState.page = newState.page || 1;
                newState.filters = objectHelper(newState.filters);
                if (newState.filters && !newState.filters.status)
                  newState.filters["status"] = null;
                // if (_.isEmpty(newState.filters)) newState["clearAll"] = true;
                console.log("data table filters", newState);
                handleChange(_.pickBy(newState, _.identity));
              }}
              filter={filterFactory()}
              rowEvents={rowEvents}
              rowStyle={hover && { cursor: "pointer" }}
              // selectRow={getSelectRow()}
              {...paginationTableProps}
            ></BootstrapTable>
          </Pagination>
        );
      }}
    </PaginationProvider>
  );
}

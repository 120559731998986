import axios from "axios";
import { REACT_APP_API } from "../../config";
import React from 'react'
import { getLocalToken } from '../Utils'
import { message } from "antd";

const token = localStorage.getItem("token")
export const handleDataRequest = async (
    method, 
    resourceUrl, 
    reqData, 
    isProtected = true
) => {
    if(!["GET", "PUT", "POST", "PATCH", "DELETE"].includes(method)){
        return Promise.reject(new Error("Pass a valid method"))
    }

    

    if(isProtected && !token){
        return Promise.reject(new Error("Token not present"));
    }

    try{
        const response = await axios({
            method: `${method}`,
            url : `${resourceUrl}`,
            headers : {
                "content-type" : "application/json",
                Authorization : `Bearer ${token}`,
            },
            ...(reqData && {data: reqData}),
        });
        return Promise.resolve(response.data);
    } catch(err){
        return Promise.reject(err.response);
    }
}

export const request = async ({ url, body, query, type }) => {
  return axios({
    url: REACT_APP_API + url,
    data: body,
    params: { ...query },
    method: type,
    headers: {      
      "Access-Control-Allow-Origin": "*",
      "Access-Control-Allow-Credentials": true

    },
  }).catch((err) => new Error(err));
};

export const request1 = async ({ url, body = {}, query, type }) => {
  return axios({
    url: REACT_APP_API + url,
    data: body,
    params: { ...query },
    method: type,
    headers : {
      "content-type" : "application/json",
      Authorization : `Bearer ${token}`,
  },

    // ...(authToken && {
    //   headers: {
    //     Authorization: "Bearer " + authToken,
    //   },
    // }),
  }).catch((err) => {
    // new Error(err)
    console.log("xxxxxxxxxxxxxxxxxxxxxxxxx", err.response);
    if (err.response && err.response.data && err.response.data.message) {
      let errArray = err.response.data.message.split("'");
      if (errArray && errArray.length > 1) {
        message.error(errArray[1] + " " + errArray[3]);
      } else {
        message.error(err.response.data.message);
      }
    }
  });
};

// const token = localStorage.getItem("token")
export const getrequest = async ({ url, body, query, type, headers }) => {
  return axios({
    url: REACT_APP_API + url,
    data: body,
    params: { ...query },
    method: type,
    headers: {
      Authorization: `Bearer ${token}`,
      "content-type": "application/json",
       },
  }).catch((err) => new Error(err));
};

// export const handleRequestGet = async (url, rawBodyData = {}) => {
//   const body = new FormData();
//   // const esc = encodeURIComponent
//   var esc = encodeURI;
//   var queryString = Object.keys(rawBodyData)
//     .map((k) => esc(k) + "=" + esc(rawBodyData[k] ? rawBodyData[k] : ""))
//     .join("&");

//   let finalUrl = url + "?" + queryString;

//   return axios
//     .get(`${REACT_APP_API}${finalUrl}`, { headers: getAuthHeader() })
//     .catch((err) => {
//       console.log("API error", err);
//     });
// };


// const instance = axios.create({
//   baseURL: 'https://mashroom-node.herokuapp.com/api/',
//   timeout: 1000,
//   headers: {'Authorization': 'Bearer '+token}
// });


// const handleRequestGet = async (url, rawBodyData = {}) => {
//   const body = new FormData();
//   // const esc = encodeURIComponent
//   var esc = encodeURI;
//   var queryString = Object.keys(rawBodyData)
//     .map((k) => esc(k) + "=" + esc(rawBodyData[k] ? rawBodyData[k] : ""))
//     .join("&");

//   let finalUrl = url + "?" + queryString;

//   return axios
//     .get(`${API_BASE_URL}${finalUrl}`, { headers: getAuthHeader() })
//     .catch((err) => {
//       console.log("API error", err);
//     });
// };


// export const getProfile = (data, id) =>
//   handleRequestGet("/user/enduser/" + id, data);

export const retrieveProject = (data) =>
  request1({ url: `sellers/${data}`, type: "GET" });

export const retrieveProduct = (data) =>
  request1({ url: `products/${data}`, type: "GET" });


export const login = (data) =>
  request({ url: "auth/signin/", body: data, type: "POST" });

export const createseller = (data) =>
  request({ url: "auth/signup/", body: data, type: "POST" });

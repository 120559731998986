import React from "react";
import { Redirect } from "react-router-dom";
import { useSelector } from "react-redux";

import UpdateProfile from "./UpdateProfile";
import FirstPassword from "./FirstPassword";

const Onboarding = () => {
  const { user } = useSelector((state) => state.auth);
  const { status, updateProfile } = user;

  return (
    <div>
      {status !== "approved" ? (
        <>
          {updateProfile ? (
            <>
              <FirstPassword />
            </>
          ) : (
            <>
              <UpdateProfile />
            </>
          )}
        </>
      ) : (
        <Redirect to="/" />
      )}
    </div>
  );
};

export default Onboarding;
